export default {
  globalActions: {
    save:   'Save',
    edit:   'Edit',
    delete: 'Delete',
    cancel: 'Cancel',
  },
  breadcrumbs: {
    search:        'Search',
    account:       'Account',
    capture:       'Capture',
    imageStudio:   'Image Studio',
    dermaidStudio: 'oVioAi Studio',
  },
  pageTitles: {
    common:                'oVio Workshop',
    login:                 'Login',
    accounts:              'Accounts',
    account:               'Account',
    capture:               'Capture',
    captures:              'Captures',
    tags:                  'Tags',
    forgotPassword:        'Forgot Password',
    userPage:              'User Page',
    settings:              'Settings',
    passwordReset:         'Password Reset',
    imageStudioReview:     'Image Studio Review',
    imageStudio:           'Image Studio',
    dermaidStudio:         'oVioAi Studio',
    videoStudioReview:     'Video Studio Review',
    videoStudio:           'Video Studio',
    exportCaptureDownload: 'Export Capture Download',
  },
  accountsPage: {
    title:    'Accounts',
    filters:  'Advanced Filter',
    advanced: 'Advanced',
  },
  accountPage: {
    title:                       'Account',
    notFound:                    'Account not found',
    deleteTitle:                 'Delete Account',
    deleteText:                  'Are you sure you want to delete this account?',
    deleteError:                 'Can\'t delete the account',
    captureAssociatedErrorTitle: 'Delete Error',
    captureAssociatedError:      'Captures associated with the account must be deleted first',
    details:                     {
      title: 'Info',
    },
    captures: {
      title:       'Captures',
      deleteTitle: 'Delete Capture',
      deleteText:  'Are you sure you want to delete this capture?',
      deleteError: 'Can\'t delete the capture',
      presets:     'Add Presets to Image Studio',
      video:       'Add Video to Video Studio',
      uploading:   'Uploading {0} of {1}',
    },
    imageStudio: {
      title:         'Image Studio Projects',
      deleteProject: 'Delete Project',
      deleteDialog:  {
        deleteText: 'Are you sure you want to delete this Image Studio Project?',
        success:    'Image Studio Project was successfully deleted!',
        error:      'Failed to delete the Image Studio Project!',
      },
    },
    dermaidStudio: {
      title:         'oVioAi Studio Projects',
      deleteProject: 'Delete Project',
      deleteDialog:  {
        deleteText: 'Are you sure you want to delete this oVioAi Studio Project?',
        success:    'oVioAi Studio Project was successfully deleted!',
        error:      'Failed to delete the oVioAi Studio Project!',
      },
    },
    videoStudio: {
      title:         'Video Studio Projects',
      deleteProject: 'Delete Project',
      deleteDialog:  {
        deleteText: 'Are you sure you want to delete this Video Studio Project?',
        success:    'Video Studio Project was successfully deleted!',
        error:      'Failed to delete the Video Studio Project!',
      },
    },
    documents: {
      title: 'Documents',
      error: 'Failed to update document',
    },
    gender: [ 'Select Gender', 'Male', 'Female', 'Other' ],
  },
  capturesPage: {
    title:           'Captures',
    filterTags:      'Tags',
    filterUsers:     'Users',
    filterMachines:  'Machines',
    filterLocations: 'Locations',
    filterStartDate: 'From',
    filterEndDate:   'To',
    item:            {
      addDermaid:  'Add to oVioAi Studio',
      addVideo:    'Add Video to Studio',
      addPresets:  'Add Preset Images to Studio',
      viewAccount: 'View Account',
    },
    uploading: 'Uploading {0} of {1}',
  },
  tagsPage: {
    title: 'Tags',
  },
  capturePage: {
    accountBreadcrumb: 'Capture',
    videoNotSupport:   'Your browser does not support HTML5 video.',
    scanModes:         [
      '360° 30fps 1°/frame',
      '180° 30fps 1°/frame',
      '360° 30fps 1.5°/frame',
      '180° 30fps 1.5°/frame',
      '360° 24fps 1°/frame',
      '180° 24fps 1°/frame',
      '360° 20fps 1°/frame',
      '180° 20fps 1°/frame',
      '360° 15fps 1°/frame',
      '180° 15fps 1°/frame',
    ],
    notFound: 'Capture not found',
    title:    'Capture',
    fields:   {
      account:       'Account',
      timeOfCapture: 'Time of Capture',
      location:      'Location',
      user:          'User',
      tags:          'Tags',
    },
    view:                 'View',
    information:          'Information',
    delete:               'Delete',
    export:               'Export',
    captureInfoTitle:     'Capture Information',
    captureInfoTab:       'Capture',
    cameraInfoTab:        'Camera',
    captureReassignTitle: 'Reassign Account',
    reassignCapture:      'Reassign Account',
    createAvatar:         'Create Avatar',
    createAvatarSuccess:  'Avatar is being created, please check back in a minute.',
    localExportCapture:   'Capture is being exported and will download when completed.',
    cloudExportCapture:   'Capture is being exported and you will receive an email when it is complete.',
    exportInProgress:     'An export is already in progress, please wait until it is complete before submitting another.',
    existsInImageStudio:  'Capture already exists in Image Studio',
    reassignSuccess:      'Capture has been assigned to a new Account',
    reassignError:        'Failed to assign capture to a new Account',
    currentAccount:       'Current Account',
    newAccount:           'Select New Account',
    audioPlayer:          'Audio',
    details:              {
      frameNumber: 'Frame Number',
      frameAngle:  'Angle',
    },
    select: {
      titleImageStudio:   'Image Studio: ',
      titleVideoStudio:   'Video Studio: ',
      titleDermaidStudio: 'oVioAi Studio: ',
      dialog:             {
        title:  'Select Frames',
        from:   'From',
        to:     'To',
        every:  'Every',
        accept: 'ACCEPT',
        cancel: 'CANCEL',
      },
      options: {
        all:     'All',
        presets: 'Presets',
        range:   'Range',
        video:   'Video',
      },
      actions: {
        clearSelected: 'Clear Selected',
        addSelected:   'Add Selected',
        clearVideo:    'Remove Video',
        addVideo:      'Add Video',
        downloadVideo: 'Download Video',
      },
    },
    select3D: {
      optionsTitle: 'Render Options:',
      options:      {
        color:     'Color',
        grey:      'Grey',
        wireframe: 'Wireframe',
      },
      exportModel: 'Export Model',
    },
    createTag:  'No matching results found.Press [ENTER] to create a new one.',
    filterTags: 'Tags',
  },
  loginPage: {
    login:                'SIGN IN',
    forgotPassword:       'FORGOT PASSWORD',
    passwordRequest:      'RESET PASSWORD',
    username:             'Enter your username',
    email:                'Enter your email',
    password:             'Enter your password',
    usernameValidate:     'Username is required',
    emailValidate:        'Valid Email is required',
    passwordValidate:     'Password is required',
    error:                'Username or Password is incorrect',
    errorPassword:        'Failed to submit password reset',
    browserMessage:       'The browser is not supported.',
    unknownError:         'Failed to login.',
    passwordResetSuccess: 'A password reset link has been sent to your email address.',
  },
  passwordResetPage: {
    submit:                'SUBMIT',
    enterPassword:         'Enter your password',
    confirmPassword:       'Retype your password',
    passwordValidate:      'Password is required',
    passwordMatchValidate: 'Passwords do not match',
    error:                 'Failed to update password',
  },
  header: {
    account: 'Account',
    logOut:  'Log Out',
    search:  'Search',
  },
  sidePanel: {
    accounts:      'Accounts',
    captures:      'Captures',
    tags:          'Tags',
    imageStudio:   'Image Studio',
    dermaidStudio: 'oVioAi Studio',
    videoStudio:   'Video Studio',
    settings:      'Settings',
    help:          'Help',
  },
  organizationSettingsTab: {
    edit:          'Edit',
    name:          'Enable 2FA',
    details:       'Enable two-factor authentication for all users.',
    mfaSettings:   'Edit MFA Settings',
    passkey:       'Passkey',
    authenticator: 'Authenticator',
    fields:        {
      key:         'Key',
      active:      'Active',
      actions:     'Actions',
      description: 'Description',
      method:      'Method',
      save:        'Save',
      cancel:      'Cancel',
    },
  },

  userSettingsTab: {
    add:     'Add',
    edit:    'Edit',
    search:  'Search User',
    delete:  'Delete',
    addUser: {
      title:             'Add User',
      ok:                'Save',
      cancel:            'Cancel',
      userAlreadyExists: 'An account with this email address already exists.',
      success:           'User was saved.',
      error:             'User failed to save.',
    },
    deleteUser: {
      title:   'Delete User',
      submit:  'Delete',
      message: 'Are you sure you want to delete this user?',
      success: 'User was successfully deleted!',
      error:   'Failed to delete the user!',
    },
    editUser: {
      title:                'Edit User',
      ok:                   'Save',
      reset:                'Reset Password',
      passwordResetSuccess: 'Password reset instructions sent successfully.',
      passwordResetError:   'Failed to reset password. Please try again.',
    },
    username:        'Username*',
    displayname:     'Display Name*',
    password:        'Password*',
    confirmPassword: 'Confirm New Password',
    email:           'Email Address*',
    permissionLevel: 'Permission Level',
    deactivateUser:  {
      title:   'Deactivate User',
      submit:  'Deactivate',
      message: 'Are you sure you want to deactivate this user?',
    },
    activateUser: {
      title:   'Activate User',
      submit:  'Activate',
      message: 'Are you sure you want to activate this user?',
      cancel:  'Cancel',
      error:   'Failed to (De)Activate User!',
    },
    table: {
      headers: {
        username:    'User Name',
        displayname: 'Display Name',
        email:       'Email Address',
        accessLevel: 'Permission Level',
        active:      'Active',
        actions:     'Actions',
      },
      active:   'Active',
      inactive: 'Inactive',
    },
  },
  machinesTab: {
    editBtn:      'Edit',
    saveFinished: 'Machine info was saved!',
    saveError:    'An issue was encountered while saving the machine',
    headers:      {
      id:                'Id',
      name:              'Name',
      description:       'Description',
      customer_location: 'Location',
    },
    editMachines: {
      title:            'Update Machine',
      name:             'Name',
      description:      'Description',
      customerLocation: 'Customer Location',
      save:             'Save',
      cancel:           'Cancel',
    },
  },
  locationsTab: {
    addBtn:       'Add',
    editBtn:      'Edit',
    saveFinished: 'Location was saved!',
    saveError:    'An issue was encountered while saving the location',
    addLocation:  {
      title:    'Add Location',
      name:     'Name*',
      street:   'Street',
      city:     'City',
      state:    'State',
      zip:      'Zip',
      contact:  'Primary Contact',
      phone:    'Phone Number',
      timezone: 'Timezone*',
      add:      'Add',
      save:     'Save',
      cancel:   'Cancel',
    },
    editLocation: {
      title: 'Edit Location',
    },
    headers: {
      id:             'Id',
      name:           'Name',
      street:         'Street',
      city:           'City',
      state:          'State',
      zip:            'Zip',
      primaryContact: 'Primary Contact',
      contactPhone:   'Phone Number',
      timezone:       'Timezone',
    },
  },
  addAccount: {
    title:         'Add Account',
    ok:            'Add Account',
    cancel:        'Cancel',
    error:         'Server error.',
    requiredField: 'This is a required field.',
    success:       'Account was added successfully',
    invalidEmail:  'Invalid email.',
    invalidDate:   'Invalid date.',
  },
  editAccount: {
    title:   'Edit Account',
    ok:      'Save Account',
    cancel:  'Cancel',
    success: 'Account has been saved successfully!',
    error:   'Server error.',
  },
  globalSettingsPage: {
    title: 'Settings',
    tabs:  {
      users:                 'Users',
      accountDataFields:     'Account Data Fields',
      accountDocumentFields: 'Account Documents Fields',
      machines:              'Machines',
      locations:             'Locations',
      tags:                  'Tags',
      systemDiagnostics:     'System Diagnostics',
      auditLog:              'Audit Log',
      presetFrameProfiles:   'Preset Frame Profiles',
      organizationSettings:  'Organization Settings',
    },
  },
  permissionNames: {
    user1:            'User 1',
    user2:            'User 2',
    administrator:    'Administrator',
    orgAdministrator: 'Organization Administrator',
    technician:       'Technician',
    oVio:             'oVio Internal',
  },
  auditLog: {
    logTable: {
      username: 'User Name',
      message:  'Message',
      date:     'Date',
    },
    export: 'Export Audit',
  },
  presetFrameProfiles: {
    edit:         'Edit',
    delete:       'Delete',
    add:          'Add',
    deleteDialog: {
      title:   'Delete Preset Frame Profile?',
      submit:  'Delete',
      message: 'Are you sure you want to delete this Preset Frame Profile?',
      cancel:  'Cancel',
      success: 'Preset Frame Profile was successfully deleted!',
      error:   'Failed to delete the Preset Frame Profile!',
    },
    addDialog: {
      title:        'Add new Preset Frame Profile',
      submit:       'Add',
      cancel:       'Cancel',
      success:      'Preset Frame Profile was successfully added!',
      error:        'Failed to add the Preset Frame Profile!',
      name:         'Name',
      presetFrames: 'Preset Frames',
    },
    updateDialog: {
      title:   'Update Preset Frame Profile',
      submit:  'Save',
      success: 'Preset Frame Profile was successfully updated!',
      error:   'Failed to update the Preset Frame Profile!',
    },
    profilesTable: {
      id:     'Id',
      name:   'Name',
      frames: 'Frames',
    },
  },
  systemDiagnostics: {
    diagnosticsTable: {
      id:    'Id',
      type:  'Type',
      types: {
        1: 'Debugging',
        2: 'Info',
        3: 'Warning',
        4: 'Error',
        5: 'Critical',
      },
      message:     'Message',
      messageTime: 'Message Time',
    },
    export: 'Export Diagnostic',
  },
  accountDataFields: {
    edit:       'Edit',
    delete:     'Delete',
    add:        'Add',
    fieldTypes: {
      text:   'Text',
      gender: 'Gender',
      date:   'Date',
      height: 'Height',
      phone:  'Phone',
      email:  'Email',
    },
    success:              'Account data field has been added successfully!',
    keyMaxLength:         'Key field should not be greater than 45 characters',
    keyRequired:          'Key field can not be empty',
    descriptionMaxLength: 'Description field should not be greater than 100 characters',
    descriptionRequired:  'Description field can not be empty',
    sortOrderRequired:    'Sort order field can not be empty',
    headers:              {
      id:             'Id',
      description:    'Description',
      permanent:      'Permanent',
      type:           'Type',
      visible:        'Visible',
      width:          'Width',
      table_position: 'Table Position',
    },
    addDialog: {
      addTitle:      'Add New Account Field',
      editTitle:     'Edit Account Field',
      key:           'Field Key*',
      description:   'Field Description*',
      sortOrder:     'Field Sort Order',
      required:      'Required',
      visible:       'Visible',
      type:          'Field Type*',
      tablePosition: 'Table Position',
      add:           'Add',
      save:          'Save',
      cancel:        'Cancel',
      success:       'Account Field has been saved successfully!',
      error:         'Failed to save the Account Field!',
      columnError:   'Column position already exists!',
    },
    deleteDialog: {
      title:   'Delete Account Data Field',
      submit:  'delete',
      message: 'Are you sure you want to delete this Account Data Field?',
      cancel:  'Cancel',
      success: 'Account Field was successfully deleted!',
      error:   'Failed to delete the Account Field!',
    },
  },
  accountDocumentFieldsTab: {
    addBtn:    'Add',
    editBtn:   'Edit',
    deleteBtn: 'Delete',
    error:     'There was an error with this request.',
    headers:   {
      id:          'Id',
      key:         'Key',
      description: 'Description',
      visible:     'Visible',
    },
    addField: {
      title:       'Add Document Field',
      add:         'Add',
      cancel:      'Cancel',
      key:         'Key',
      description: 'Description',
      visible:     'Visible',
    },
    editField: {
      title: 'Edit',
      save:  'Save',
    },
    delete: {
      title:    'Delete Field?',
      question: 'This operation may fail if there are still associated documents in the database. Are you sure you want to delete this field?',
      ok:       'Delete',
      cancel:   'Cancel',
    },
  },
  tagsTab: {
    tags:      'Tags',
    addBtn:    'Add',
    deleteBtn: 'Delete',
    preset:    'Preset',
    notPreset: 'Not Preset',
    search:    'Search Tags',
    deleteTag: {
      title:              'Delete Tag?',
      confirmText:        'Are you sure you want to delete this tag?',
      confirmHasCaptures: ' captures are associated with this tag.',
      delete:             'Delete',
      cancel:             'Cancel',
      success:            'The tag was successfully deleted!',
      error:              'Failed to delete the tag!',
    },
    addTag: {
      title:   'Add Tag',
      add:     'Add',
      cancel:  'Cancel',
      name:    'Name',
      preset:  'Preset',
      success: 'The tag was successfully added!',
      error:   'Failed to add the tag!',
    },
    headers: {
      id:           'Id',
      name:         'Name',
      preset:       'Preset',
      captureCount: 'Capture Count',
    },
  },
  imageStudio: {
    title:         'Image Studio',
    account:       'Account',
    accounts:      'Accounts',
    capture:       'Capture',
    tag:           'Tag',
    toolsTitle:    'Drawing Tools',
    navToolsTitle: 'Type & Navigation Tools',
    saveEdits:     'Save Edits',
    undo:          'Undo',
    clear:         'Clear',
    removePage:    'Remove From Page',
    tooltips:      {
      freeDraw:  'Free Draw',
      ellipse:   'Ellipse',
      rectangle: 'Rectangle',
      arrow:     'Arrow',
      undo:      'Undo',
      crop:      'Crop',
      text:      'Text',
      lineSize:  'Size',
    },
    sideBar: {
      accounts:         'Accounts',
      captures:         'Captures',
      addToPage:        'Add to Current Page',
      removeFromStudio: 'Remove from Image Studio',
    },
    exportDialog: {
      error: 'Failed to export the project',
      title: 'Folder information',
    },
  },
  dermaidStudio: {
    title:   'oVioAi Studio',
    sideBar: {
      accounts:         'Accounts',
      captures:         'Captures',
      results:          'Results',
      details:          'Details',
      detectedlesions:  'Detected Lesions',
      addToPage:        'Add to Current Page',
      removeFromStudio: 'Remove from oVioAi Studio',
    },
    lesionDetail: 'Lesion Detail',
  },
  userPage: {
    title:             'My Account',
    changeImgButton:   'Change Profile Image',
    userNameTitle:     'Change User Name',
    confirmButton:     'Confirm',
    changePasswordBtn: 'Change Password',
    emailTitle:        'Change Email',
    changePassword:    {
      title:           'Change Password',
      oldPassword:     'Old Password',
      newPassword:     'New Password',
      confirmPassword: 'Confirm Password',
      submit:          'Submit',
      cancel:          'Cancel',
    },
    editUser: {
      success:      'User has been saved successfully!',
      successImage: 'User image has been saved successfully!',
      error:        'Server error.',
    },
    addUser: {
      success: 'User has been added successfully!',
      error:   'Server error.',
    },
  },
  videoStudio: {
    title:                   'Video Studio',
    overlayOptionsMenuTitle: 'Overlay Options',
    overlayOptionsDate:      'Date',
    overlayOptionsTimestamp: 'Timestamp',
    save:                    'Save Video',
    saveFinished:            'Your video has been saved. You will receive an email with a link for the video shortly.',
    saveStarted:             'Your video is being exported and the download will begin shortly.',
    saveError:               'There was an issue saving your video.',
    capture:                 'Capture',
    playlistTooltip:         'Playlist',
    compareTooltip:          'Compare',
    fullscreen:              'Fullscreen',
    play:                    'Play',
    pause:                   'Pause',
    videoCompareSideBySide:  {
      sideBar: {
        title:          'Videos',
        activeVideos:   'Playing',
        inactiveVideos: 'Available',
        removePlaying:  'Remove from Player',
        removeStudio:   'Remove from Video Studio',
        AddPlaying:     'Add To Player',
      },
    },
    videoComparePlaylists: {
      prev:    'Previous',
      next:    'Next',
      sideBar: {
        title:          'Videos',
        player1:        'Player 1',
        player2:        'Player 2',
        inactiveVideos: 'Available',
        addPlayer1:     'Add to Player 1',
        addPlayer2:     'Add to Player 2',
        movePlayer1:    'Move to Player 1',
        movePlayer2:    'Move to Player 2',
        removePlaying:  'Remove from Players',
        removeStudio:   'Remove from Video Studio',
        AddPlaying:     'Add To Player',
      },
    },
  },
  validations: {
    required:  'This field is required',
    max:       'This field must be {length} characters or less',
    min:       'This field must be {length} characters or more',
    email:     'This field must be a valid email',
    image:     'you must select an image to upload',
    confirmed: 'The password fields must match',
    alpha_num: 'Only characters and numbers allowed',
    max_value: 'The field field must be {max} or less',
    min_value: 'The field field must be {min} or more',
  },
  fields: {
    password:     'Password',
    username:     'Username',
    email:        'Email',
    access_level: 'Access Level',
  },
  videoStudioReviewPage: {
    title:                'Video Studio Review',
    infoTitle:            'Video Info',
    exportType:           'Export Type',
    capture:              'Capture',
    videoInfoTab:         'Information',
    playlist:             'Playlist',
    exportTypeComparison: 'Side by Side Comparison',
    exportTypePlaylist:   'Side by Side Playlist',
    exportTypeUnknown:    'Unknown',
    info:                 'Info',
    download:             'Download',
  },
  imageStudioReviewPage: {
    title:     'Image Studio Review',
    infoTitle: 'Export Info',
    info:      'Info',
    download:  'Download',
  },
  exportCaptureDownloadPage: {
    title:    'Export Capture Download',
    download: 'Download',
  },
  studioConfirmationDialog: {
    title:                    'Are you sure you want to log out?',
    imageStudioDescription:   'Images will be deleted from ImageStudio upon logging out.',
    videoStudioDescription:   'Videos will be deleted from VideoStudio upon logging out.',
    dermaidStudioDescription: 'Images will be deleted from oVioAi Studio upon logging out.',
  },
};
